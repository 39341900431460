import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DeleteButtonComponent } from "./delete-button/delete-button.component";
import { FxJsonEditorComponent, JsonEditorOptions } from '@Common/json-editor';


const BASE = `{ "key": "", "value": "", "type": "string"}`;
@Component({
  selector: 'app-keyvalue',
  templateUrl: './keyvalue.component.html',
  styleUrls: ['./keyvalue.component.css']
})
export class KeyValueComponent implements OnInit {
  constructor(private cdr: ChangeDetectorRef) { }
  
  @Output () onUpdate = new EventEmitter ();
  @Output() startEditing = new EventEmitter();
  @Output() stopEditing = new EventEmitter();
  @Input () gridClass = '';
  @Input () service;
  @Input() noneText = 'No keys'
  @Input () set json (j) {
    this._json = j;
    this.gridOptions.data = Object.keys(j).map(c => {
      return { key: c, value: j[c] }
    });
    this.cdr.detectChanges ();
  };
  @ViewChild(FxJsonEditorComponent, { static: false }) editor: FxJsonEditorComponent;

  editorOptions = new JsonEditorOptions();
  data = {}
  
  @Input () viewOnly = false;
  _json;
  options =  ['string', 'number', 'boolean', 'null'];
  gridOptions = {
    rowSelection: 'single',
    deleteRowButton: !this.viewOnly,
    columnDefs: [
      { headerName: 'Key', field: 'key', editable: true },
      { headerName: 'Value', field: 'value', editable: true },
      { headerName: 'Type', field: 'value', cellRenderer: params => params.value === null ? 'null' : (typeof params.value).toLowerCase() }
    ]
  } as any;
  ngOnInit() {
   

  }
  ngAfterViewInit () {
   

  }
  kvData() {
    return this.gridOptions.data.filter(c => c.value !== null);
  }
  showAdd () {
    return !this.viewOnly;
  }
  disableAdd() {
    if(!this.gridOptions.data.length)
      return false;
    return this.gridOptions.data[this.gridOptions.data.length -1].key.length === 0
  }
  add () { 
   
    this.gridOptions.data.push({ key: '', value: '' });
    
  }
  enableAdd () {
    return true;
  }
  removeRow(i) {
    this.gridOptions.data.splice(i, 1); 
    this.mapData();
    this.cdr.detectChanges();
  }
  toJson () {
    
  }
  keyChanged($event, i) {
    this.gridOptions.data[i].key = $event.target.value;
    if(this.gridOptions.data[i].key && this.gridOptions.data[i].value) {
      this.mapData();
    }
  }
  valueChanged($event, i) {
    this.gridOptions.data[i].value = $event.target.value;
    if(this.gridOptions.data[i].key && this.gridOptions.data[i].value) {
      this.mapData();
    }
      
  }
  mapData() {
    let o = {};
    this.gridOptions.data.forEach(c => {
      o[c.key] = c.value;
    });
    this.onUpdate.emit(o);
  }
 
  

}
