import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Action } from "@Common/page/action";


@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit{
  @Input() disabled = false;
  @Input () show: Boolean;
  @Input () actions:  Array<Action>;
  @Input() text = '';
  @Input() myClass: any;
  @Output() isOpen = new EventEmitter()
  constructor() { 
  }

  ngOnInit() {
  
  }
  hide (fn: Function ){
    if(!fn)
      return false;
    return fn ();
  }
  makeId(action) {
    return 'action-' + action.name.toLowerCase().replace(/\W+/g,'-')
  }
  openChange($event) {
    this.isOpen.emit($event);
  }
  isDisabled() {
    return this.disabled;
  }
  disable(fn: Function) {
    if(!fn)
      return false;
    return fn();
  }
}
