import { Component, OnInit, Input, Output, EventEmitter,ViewEncapsulation, forwardRef, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SortEvent } from 'primeng/api';
import { JsonEditorOptions } from '../json-editor-options';

@Component({
  selector: 'fx-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'wh-100 d-flex flex-column',
    '(change)': '_onChange(json)',
    '(blur)': '_onTouched()'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FxJsonEditorComponent),
      multi: true,
    }
  ]
})
export class FxJsonEditorComponent implements OnInit, ControlValueAccessor {

  myJson = "";
  @Input() options = new JsonEditorOptions();
  errMessage = ""; 
  private _json;
  private _disabled = false;
  private _onChange = (json:any) => {};
  private _onTouched = () => {};
  get json() {
    return JSON.parse(this._json);
  }
  get disabled() {
    return this._disabled || (this.options.modes.length && (this.options.modes[0] == 'view'));
  }
  writeValue(obj: any) : void {

    this.myJson = JSON.stringify(obj, undefined, 2);
    this.apply();
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }
  constructor() { }
  mySelections = [];
  ngOnChanges(changes: SimpleChanges) {
    if(changes?.options.currentValue) {

    }
  }
  ngOnInit() {

  }
  apply(){
    try {
      this._json = JSON.parse(this.myJson);
      this._onChange(this._json);
      this.errMessage = "";
    } catch(e) {
      if(!this.myJson.length) {
        this.errMessage = "";
        return;
      }
      this.errMessage = e.message;
    }
    
  }
  textClass() {
    try {
      JSON.parse(this.myJson);
      return 'border'; 
    } catch(e) {
      return 'border border-alert'
    }
  }
}
