import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ConfigService, } from '@Common/config';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  cs$;
  constructor(private cs: ConfigService, private cdr: ChangeDetectorRef) { }
  config = {} as any
  modules = [];
  ngOnInit(): void {
    this.cs$ = this.cs.subscribe(d => {
      this.config = d;
      this.cdr.detectChanges();
      this.modules = Object.keys(d.modules).map(c => {
        return { name: c, version: d.modules[c] };
      });

    });
  }
  ngOnDestroy() {
    this.cs$.unsubscribe();
  }

  extension() {
    return (navigator.userAgent.indexOf("Firefox") != -1 );
  }

}
