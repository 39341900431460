
  <a class='header-button btn btn-link text-light text-decoration-none' [href]="href()" 
  id='managemenu'>
    <fa-icon icon="chart-simple" size="2x" class="d-block"></fa-icon>
    Manage
</a>
<!-- 
  <div ngbDropdownMenu aria-labelledby="management">
      <button *ngIf="showSession()"
      id="manage-sessions" class='px-3 btn btn-link dropdown-item' 
        target="_self" (click)="admin('session')" >
        <span class="mr-1">
          <fa-icon [icon]="['far', 'display']"></fa-icon>
        </span>Sessions</button> 
      <button  *ngIf="showServer()"
      id="manage-servers" class='px-3 btn btn-link dropdown-item' 
        target="_self" (click)="admin('server')" >
        <span class="mr-1">
          <fa-icon [icon]="['far', 'server']"></fa-icon>
        </span>Servers</button> 
      <button  *ngIf="showUser()"
      id="manage-servers" class='px-3 btn btn-link dropdown-item' 
        target="_self" (click)="admin('user')" >
        <span class="mr-1">
          <fa-icon [icon]="['far', 'user']"></fa-icon>
        </span>Users</button> 
  </div>
 -->
