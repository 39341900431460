        <span  ngbDropdown placement="bottom">
           <button  
           class="px-0 py-0 btn-link border-0 bg-transparent"
           ngbDropdownToggle type="button"
              
            >
                <fa-icon *ngIf="select() !== 'all'; else filtered" icon="filter"></fa-icon>
                <ng-template #filtered>
                    <fa-icon [icon]="['far', 'filter']"></fa-icon>
                </ng-template>
                
            </button>
            <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                <li  *ngFor="let t of getTags ()" 
                ngbDropdownItem
                [ngClass]="{ active: name(select()) === name(t) }" (click)="select (t)"  >
                    {{ name (t) }}
                </li>
            </ul>   
              
        </span>
       
