import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.css']
})
export class DeleteButtonComponent implements OnInit  {
  params;
  constructor() { }

  ngOnInit() {
  }
  agInit (params: any): void{
    this.params = params;
  }
  refresh (): boolean {
    return false;
  }
  show () {
    return this.params.node.selected 
      && this.params.show ();
  }
  delete () {
    let nodes =  this.params.api.getSelectedNodes ();
    this.params.api.updateRowData ({
      remove: nodes.map (c => c.data)
    });
    this.params.del ();
    
  }

}
