<div>
    <table class="table table-sm m-0">
        <thead>
            <tr class="w-100">
                <th class="border-0 flex-fill"  style="width:45%">Key</th>
                <th  class="border-0 flex-fill" style="width:45%" >Value</th>
                <th  class="border-0 flex-shrink float-end" style="width:10%" >
                    <button  *ngIf="showAdd()" class="btn btn-primary myBtn float-end" (click)="add()" [disabled]="disableAdd()">
                        <fa-icon icon="plus"></fa-icon>
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="!gridOptions.data.length">
                <td rpwspan="3" class="border-0">{{ noneText }}</td>
            </tr>
            <tr *ngFor="let k of kvData(); index as i" class="py-0 border-0">
                <td class="border-0">
                    <input type="text" [value]="k.key" class="form-control" (change)="keyChanged($event, i)" />
                </td>
                <td  class="border-0">
                    <input type="text" [value]="k.value" class="form-control" (change)="valueChanged($event, i)" />
                </td>
                <td  class="border-0 float-end">
                    <button class="btn btn-outline-primary myBtn" (click)="removeRow(i)" >
                        <fa-icon icon="xmark"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

