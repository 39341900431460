import { Component, OnInit, Input, Inject, OnDestroy, ChangeDetectorRef,
  ViewChild, ElementRef, TemplateRef,
  Renderer2, AfterViewInit, ViewContainerRef, ComponentFactoryResolver} from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { UserService } from '@Common/user/user.service';
import { ComponentLoaderService } from '@Common/component-loader';
import { AdminComponent } from '@Common/page/ui/admin/admin.component';
import { ListItemComponent } from '@Common/page/ui/header/list-item/list-item.component';
import { ConfigService, PermissionsConfigService, UserConfigService } from '@Common/config';
import { ExternalUrlService } from '@Common/util/external-url.service';



@Component({
  selector: 'fx-common-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input () title;
  @Input () user;
  @Input () page;
  @Input () base = ".."
  config$;
  ucs$;
  perms = {} as any;
  banner = '';
  @ViewChild ("actionlist", {read: ViewContainerRef, static: false }) actions: ViewContainerRef;
  @ViewChild("motdModal") motdModal: TemplateRef<any>;

  admin;
  motd;
  conf = {} as any;
  showFile;
  constructor(
    private ucs: UserConfigService,
    private config: ConfigService,
    private externalUrl: ExternalUrlService,
    private cdr: ChangeDetectorRef,
    private modal: NgbModal) {

  }
  ngOnInit() {
    this.ucs$ = this.ucs.subscribe(d => {
      this.user = d.user;
      this.perms = d.permissions;
      this.banner = d.config?.banner || '';

      this.showFile =  this.perms.user && this.perms.user.utils['file-download'] || this.perms.user.utils['file-upload'];
      this.cdr.detectChanges();

    });
    this.config$ = this.config.subscribe(d => this.conf = d)
   
  }
  ngAfterViewInit() {

  }
  ngOnDestroy () {
    this.config$.unsubscribe ();
    this.ucs$.unsubscribe();
  }
  home (index = "") {
    try {
      return this.base + '/session/' + index;
    } catch (e) {

    }
    return this.base + '/session/' + index;
  }
  shared() {
    return this.base + '/share'; 
  }
  logo () {
    return [this.base, 'img', 'logo'].join ('/');
  }
  goToFileManager() {
    this.externalUrl.newWindow([this.base, 'user','file-manager'].join ('/'))
  }

}
