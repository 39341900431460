import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { BookmarkService } from '@Common/session/actions/start/bookmark/bookmark.service';
import { Bookmark } from "@Common/types/bookmark";
import { CommandChangedService } from '@Common/session/actions/start/start/command-changed.service';
import { UserConfigService } from '@Common/config';
import { ListActionsComponent } from '../list-actions/list-actions.component';


@Component({
  selector: 'fx-session-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit {
  sub$;
  hasFocus = false;
  textFilter = '';
  sortOrder;

  @Input() view;
  @Output () selected = new EventEmitter ();
  @Output () started = new EventEmitter ();
  @Output () createCustom = new EventEmitter();

  @Input () set rootPath (p) {
    this.bs.setRootPath (p);
  }
  rowClicked = new EventEmitter();
  private _selected = <Bookmark>{};
  constructor(private bs: BookmarkService, private cc: CommandChangedService, private cdr: ChangeDetectorRef, private ucs: UserConfigService) { }
  bookmarks = <any>{};
  tag: string;
  ucr$;
  me

  ngOnInit() {
    this.sub$ = this.bs.subscribe (this.handle.bind (this));
    this.bs.setRootPath ('..')
    this.bs.load ();
    this.setSelected ({} as Bookmark);
    this.cc.id = null;
    this.ucr$ = this.ucs.subscribe((d:any) => {
      this.me = d;
      
      this.tag = window.localStorage['bookmark-tag'] ||  'all';
      this.onTagChanged(this.tag);
      this.cdr.detectChanges();
    });
    this.gridOptions.data =  this.filterByTag (this.getBookmarks (), this.tag); 
   
   
  }
  showCustom() {
    return this.me?.permissions?.user?.start?.custom;
  }
  ngAfterViewInit () {
    this.tag = window.localStorage['bookmark-tag'] ||  'all';
    this.onTagChanged(this.tag);
   
  }
  ngOnDestroy () {
    this.sub$.unsubscribe ();
    this.ucr$.unsubscribe();
  }
  setView(v) {
    this.view = v;
    window.localStorage['bookmark-view'] = this.view;
    this.cdr.detectChanges();
  }
  getView() {
    return this.view;
  }
  handle (d) {
    this.bookmarks = d;
    this.cdr.detectChanges ();
  }
  setSelected (b) {
    this._selected = b;
    this.selected.emit (b);
  }
  getBookmarks () {
    return this.bookmarks;
  }
  getSelected (b) {
    return b.id === this.cc.id;
  }
  onTagChanged (tag) {
    this.tag = tag;
    window.localStorage['bookmark-tag'] = this.tag;
    this.gridOptions.data =  this.filterByTag (this.getBookmarks (), this.tag);
  }
  filterByText (c) {
    if (!this.textFilter.length) return true;
    let l = this.textFilter.toLowerCase ();
    return ((c.command || '').toLowerCase().indexOf (l) >=0)
       || ((c.name || '').toLowerCase().indexOf (l) >= 0);
  }
  setTextFilter() {
    window.localStorage['bookmarkTextFilter'] = this.textFilter;
  }
  filterByTag (b, tag) {
    switch (tag) {
      case "favorites":
        return this.getFavorites ().filter (this.filterByText.bind (this));
      case "system":
        return (b.system || []).filter (this.filterByText.bind (this));;
      case "user": 
        return (b.user || []).filter (this.filterByText.bind (this));;
      case "history":
        return (b.history || []).filter (this.filterByText.bind (this));;
      case "all":
        return ((b.system || [])
          .concat (b.user || [])).filter (this.filterByText.bind (this));
      default: 
        return (this.getFiltered (tag, b.system)
          .concat (this.getFiltered (tag, b.user))).filter (this.filterByText.bind (this));;
    }
  }
  filteredBookmarks() {
    let b = (this.sort(this.filterByTag (this.getBookmarks (), this.tag), this.sortOrder));
  
    b.unshift({
      id: "default",
      _id: "default",
      icon: "../img/bookmark",
      name: "Custom Session",
      params: {
        appId: "default"
      }
    });
    return b;
  }
  sort(bms = [], order = '') {
    return bms.sort((a, b) => {
      let aName = (a?.name || a?.params?.name || a.command || '').toLowerCase();
      let bName = (b?.name || b?.params?.name || b.command || '').toLowerCase();
      if(aName > bName) {
        return 1;
      } else if(bName > aName) {
        return -1;
      }
      return 0;
    })
  }
  sortCaseInsensitive (arr = []) {
    return arr.sort ((a, b) => a.toLowerCase ().localCompare (b.toLowerCase ()));
  }
  tagLengths () {
    let b = this.getBookmarks ();
    return  {
      favorites: this.filterByTag (b, 'favorites').length,
      system: this.filterByTag (b, 'system').length,
      user: this.filterByTag (b, 'user').length,
      history: this.filterByTag (b, 'history').length
    }
  }
  start ($event) {
    this.started.emit ($event);
  }
  getBookmarkClasses() {
    return "float-start d-flex justify-content-center me-1 mb-1";
  }
  custom($event) {
    this.createCustom.emit($event);
  }
  private getFavorites () {
    let b = ([]
    .concat (this.bookmarks.system || [])
    .concat (this.bookmarks.user|| []))
    .filter (c => this.bookmarks.favorites && this.bookmarks.favorites.find (d => d === c.id));
    return b;
  }
  private getFiltered (tag, bookmarks) {
    if (!bookmarks) return  [];
    
    return bookmarks.filter (c => {
      if (!c.tags) return;
      return c.tags.indexOf (tag) >= 0;
    });
  }
  gridOptions ={
    domLayout: 'autoHeight',
    pagination: true,
    sortingOrder: ['asc', 'desc'],
   
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      comparator: function(a,b) {
          if (typeof a === 'string') {
               return a.localeCompare(b);
          } else {
               return (a > b ? 1 : (a < b ? -1 : 0));
          }
      }
   },
   overlayNoRowsTemplate: "No Applications",
   rowSelection: 'single',
   rowDeselection: false,
   suppressCellSelection: true,
  
   onRowDoubleClicked: ($event) => { 
      this.started.emit ($event); 
   },
  
   columnDefs: [
    {
  
      headerName: "Name", field: "name",
      componentRef: ListActionsComponent,
      className: 'col-4',
      data: {
        createCustom: ($event) => {
          this.createCustom.emit($event)
        }
      }
    },
    {
      headerName: "Command", field: "command"
    },    
    {
      headerName: "Window Mode", field: "geometry", 
      cellRenderer: (params) => {
        return params.geoemtry === 'rootless' ? 'Multiple' : 'Single';
      }
    },   

  ]
  } as any;
}
