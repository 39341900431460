import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Bookmark } from '@Common/types/bookmark';
import { BookmarkService } from "@Common/session/actions/start/bookmark/bookmark.service";
import { NotifyService, Errors } from '@Common/notify';
import { CommandChangedService } from '../../start/command-changed.service';
import { UserConfigService } from '@Common/config';


const ICON = '../img/bookmark';

@Component({
  selector: 'fx-session-bookmark-element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss']
})
export class ElementComponent implements OnInit {
  @Input() view = 'grid';
  @Input () data: Bookmark;
  @Input () selected: Boolean;
  @Output () started = new EventEmitter ();
  @Output () createCustom = new EventEmitter()
  bs$;
  favs = [];
  user = [];
  hovering = false;
  highlight = false;
  starting = false;
  ucs$;
  me;
  constructor(private bs: BookmarkService, 
    private ns: NotifyService, 
    private cc: CommandChangedService,
    private ucs: UserConfigService
    
    ) { }
  
  ngOnInit() {
    this.bs$ = this.bs.subscribe (d => {
      this.favs = d.favorites || [];
      this.user = (d.user || []).map (c => c.id) || [];
    });
    this.ucs$ = this.ucs.subscribe(d => {
      this.me = d;
    });
  }
  ngOnDestroy() {
    this.ucs$.unsubscribe();
    this.bs$.unsubscribe();
  }
  showCustom() {
    return this.me?.permissions?.user?.start?.custom;
  }
  name () {
    return this.data.name || this.data.command || this.data.id || '';
  }
  setClass () {
    return { selected: this.hovering, notSelected: !this.hovering, ccSelected: this.selected }
  }
  close () {
    
  }
  setSelect(b) {
    this.selected = b;
    if(this.selected)
      this.cc.id = this.data.id;
  }
  toggleSelect () {
    this.selected = !this.selected;
    if (this.selected) this.cc.id = this.data.id;
  }
  isSelected() {
    return this.selected;
  }
  hasIcon() {
    return this.data.icon && (this.data.icon !== '../img/bookmark');
  }
  icon () {
    if(this.data.icon)
    {
      if(this.data.icon.startsWith('/'))
        return '..' + this.data.icon;
    }
    return this.data.icon || ICON;
  }
  faIcon() {
    return  'square-' + this.name().toLowerCase()[0];
  }
  isFavorite () {
    return this.favs.indexOf (this.data.id) >= 0; 
  }
  favorite () {
    let p = this.isFavorite () ? this.bs.unfavorite (this.data.id) : this.bs.favorite (this.data.id);
    p
    .then (d => { this.bs.load () })
    .catch (e => this.ns.error (Errors.message (e)));
  }
  toggleHover (b) {
    this.hovering = b;
  }
  hover () {
    return this.hovering;
  }
  showUser () {
    return this.user.indexOf (this.data.id) >= 0;
  }
  remove () {
    this.bs.remove (this.data.id)
    .then (d => { this.bs.load () })
    .catch (e => this.ns.error (Errors.message (e)))
  }
  start () {
    this.starting = true;
    let d = JSON.parse(JSON.stringify(this.data));
    d.params = (d.params || {}) as any;
    d.params.bookmarkId = d.id;
    this.started.emit (d);
  }
  setHovering(b) {
    this.hovering = b;
  }
  setHighlight(b) {
    this.highlight = b;
  }
  custom() {
    this.createCustom.emit(this.data)
  }
  hasSessions() {
    return 0
  }

}
