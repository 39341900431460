import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Bookmark } from '@Common/types/bookmark';
import { BookmarkService } from "@Common/session/actions/start/bookmark/bookmark.service";
import { NotifyService, Errors } from '@Common/notify';
import { CommandChangedService } from '../../start/command-changed.service';
import { UserConfigService } from '@Common/config';
import { GridCellComponent } from '@Common/grid';

const ICON = '../img/bookmark';

@Component({
  selector: 'fx-session-bookmark-list-actions',
  templateUrl: './list-actions.component.html',
  styleUrls: ['./list-actions.component.scss']
})
export class ListActionsComponent implements OnInit, GridCellComponent {
  @Input() view = 'grid';
  
  @Input () selected: Boolean;
  @Output () started = new EventEmitter ();
  @Output () createCustom = new EventEmitter()
  bs$;
  favs = [];
  user = [];
  hovering = false;
  highlight = false;
  starting = false;
  ucs$;
  me;
  col;
  params;
  multiselect;
  data;
  constructor(private bs: BookmarkService, 
    private ns: NotifyService, 
    private cc: CommandChangedService,
    private ucs: UserConfigService
    
    ) { }
  
  ngOnInit() {
    this.bs$ = this.bs.subscribe (d => {
      this.favs = d.favorites || [];
      this.user = (d.user || []).map (c => c.id) || [];
    });
    this.ucs$ = this.ucs.subscribe(d => {
      this.me = d;
    });
  }
  ngOnDestroy() {
    this.ucs$.unsubscribe();
    this.bs$.unsubscribe();
  }
  showCustom() {
    return this.me?.permissions?.user?.start?.custom;
  }
  name () {
    return this.params?.name || this.params?.command || this.params?.id || '';
  }
  get isSelected() {
    return this.selected === true;
  }
  set isSelected(s) {
    this.selected = s;
  }
  
  close () {
    
  }
  setSelect(b) {
    this.selected = b;
    if(this.selected)
      this.cc.id = this.params?.id;
  }
  toggleSelect () {
    this.selected = !this.selected;
    if (this.selected) this.cc.id = this.params?.id;
  }
 
  hasIcon() {
    return this.params?.icon && (this.params?.icon !== '../img/bookmark');
  }
  icon () {
    if(this.params?.icon)
    {
      if(this.params?.icon.startsWith('/'))
        return '..' + this.params?.icon;
    }
    return this.params?.icon || ICON;
  }
  faIcon() {
    return  'square-' + this.name().toLowerCase()[0];
  }
  isFavorite () {
    return this.favs.indexOf (this.params?.id) >= 0; 
  }
  favorite () {
    let p = this.isFavorite () ? this.bs.unfavorite (this.params?.id) : this.bs.favorite (this.params?.id);
    p
    .then (d => { this.bs.load () })
    .catch (e => this.ns.error (Errors.message (e)));
  }
  toggleHover (b) {
    this.hovering = b;
  }
  hover () {
    return this.hovering;
  }
  showUser () {
    return this.user.indexOf (this.params?.id) >= 0;
  }
  remove () {
    this.bs.remove (this.params?.id)
    .then (d => { this.bs.load () })
    .catch (e => this.ns.error (Errors.message (e)))
  }
  start () {
    this.starting = true;
    this.started.emit (this.params || {});
  }
  setHovering(b) {
    this.hovering = b;
  }
  setHighlight(b) {
    this.highlight = b;
  }
  custom() {
    this.data?.createCustom(this.params || {})
  }
  hasSessions() {
    return 0
  }

}
