<section id="ui-footer" class="d-flex justify-content-end">
    <nav class="pe-4 pb-1">
        <button  *ngIf="0" [ngbPopover]="details" placement="top-left" popoverClass="pop" class="btn btn-link" type="button">
            <span class='text-muted font-weight-light' >
            Build: {{ build }}
            </span>
        </button>
        
        <ng-template #details>
            <div class='font-weight-light'>Server Id: {{ serverId }}</div>
            <div  class='font-weight-light'>Logged in Server: {{ user.iss }}</div>
        </ng-template>
        <div> 
            <a *ngIf="false" 
            class='text-white me-3'  
            href='https://www.starnet.com/fastx/current-client?version={{ build }}' 
            target='_blank'>Desktop Client</a>
        
            <a href='http://www.starnet.com' target='_blank'>
                <img src='{{ poweredBy }}'   style='height:24px'/>
            </a>
        </div>
    </nav>
</section>
