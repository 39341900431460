<div *ngIf="conf['advanced-disabled']" class="m-0 alert alert-danger text-center w-100" >
    {{ conf['advanced-disabled'] }}
</div>

<nav id="ui-header" class='navbar navbar-dark bg-dark justify-content-between ps-0 ui-header' >
    <div class="d-flex align-items-stretch">
        <div class="navbar-brand">
            <a [href]="home ('')"  class="btn btn-link shadow-none px-md-4" >
                <img src='{{ logo () }}' class='logo-sm-96 shadow-none' />
            </a>
           
            <!-- <a class='header-button btn btn-link text-light text-decoration-none' 
            [href]="home('index')">Sessions</a>
            &nbsp; -->
           
              
        
           
        </div>	
        <div class="navbar-nav d-inline">
            <div #actionlist class="d-none"></div>
        </div>
    </div>
   
    <!-- <ngb-alert  *ngIf="banner" #alert  type="info"
     (closed)="alert.close(); banner = ''" class="position-absolute start-50 top-0 translate-middle-x "  style="z-index:10">
        <div [innerHTML]="banner"></div>
    </ngb-alert> -->
    
    <div >
        <ul class="nav ">

            
         
            <li id="ui-button-admin" class="nav-item d-flex align-items-center me-3" *ngIf="perms.manager" >
                <button-admin></button-admin>
            </li>
            
            <li id="ui-button-manage"class="nav-item d-flex align-items-center me-3" *ngIf="perms.manager" >
                <button-manage ></button-manage>
            </li>
            
            <li id="ui-user-menu"  class="nav-item">
                <user-menu [base]="base" [page]="page" class=""></user-menu>
            </li>
        </ul>
    </div> 
</nav>


<ng-template #motdModal let-modal>
    
    <div class="modal-body" [innerHTML]="motd">
    </div>
    <div class="modal-footer">
        <footer-close (submit)="modal.close()"></footer-close>
    </div>
</ng-template>
