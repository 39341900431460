<div [formGroup]="form">
  <div class="mb-3">
      <label for="home">Set Home Page</label>
      <select formControlName="home" id="home" class="form-control ">
          <option *ngFor="let o of homeOpts" [value]="o.val">{{ o.name }}</option>
      </select>
  </div>
  <div class="mb-3">
    <label for="autoconnect">Autoconnect &nbsp;</label>
    <select class="form-control " id="autoconnect" formControlName="autoconnect">
       <option value="$$disable">-- Disable --</option>
       <option value="$$default">-- Default --</option>
       <optgroup label="User" *ngIf="bookmarks.user && bookmarks.user.length">
         <option *ngFor="let o of bookmarks.user" [value]="o.id">{{ o.name || o.command }}</option>
       </optgroup>
       <optgroup label="System" *ngIf="bookmarks.system && bookmarks.system.length">
          <option *ngFor="let o of bookmarks.system" [value]="o.id">{{ o.name || o.command }}</option>
        </optgroup>
    </select>
  </div>
</div>
<footer-submit-cancel 
  (submit)="onSubmit ()"
  (cancel)="onCancel ()"></footer-submit-cancel>