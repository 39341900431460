<div  ngbDropdown class="d-inline-block" placement="bottom-right" title="Notifications" *ngIf="notifications.length">
    <button  class='header-button btn btn-link text-light text-decoration-none' 
    id='notificationmenu' 
    (click)="updateNotify()"
    ngbDropdownToggle
    >
        <fa-icon icon="bell" [ngClass]="bellClasses()"></fa-icon>
    </button>
    <div ngbDropdownMenu  aria-labelledby="notificationmenu">
        <div id="ui-upgrade-link" class="nav-item">
            <div class="dropdown-item" *ngIf="showUpgrades()"
                (click)="goNotify(mapUpdateContent(up), content)"
            >
                <strong>
                    {{ upgradeText }}
                </strong>
                <div>
                    <ul>
                        <li *ngFor="let u of up.versions;">
                            <a href="{{ u.releaseNotesUrl }}">{{ u.version }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> 
        <div *ngIf="notifications.length">
            <div *ngFor="let n of notifications" id=""
                class='dropdown-item' 
                target="_self" 
                (click)="goNotify(n, content)">
                <strong>{{ n.title['#text'] | slice: 0: 30 }}</strong>
                <div [innerHTML]="n.summary['#text']"></div>
                <small>{{n.updated['#text'] | date }}</small>
            </div> 
            <div class="dropdown-item">
                <button class="btn btn-link w-100" (click)="goToNotifications()">All Notifications</button>
            </div> 
        </div> 
        <div *ngIf="!notifications.length">
            <div id="" class='dropdown-item' >
                No Notifications
            </div>
        </div>
        
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{ noti.title['#text'] }}</h5>
      <small *ngIf="noti.updated">{{ noti.updated['#text'] | date }}</small>
    </div>
    <div class="modal-body" [innerHTML]="noti.content['#text'] || noti.summary['#text']">
    </div>
    <div class="modal-footer">
        <footer-close (submit)="modal.close()"></footer-close>
    </div>
    
</ng-template>
  