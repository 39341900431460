<span [ngClass]="{ invisible: !show }" ngbDropdown (openChange)="openChange($event)"
  container="body"
>
  <span *ngIf="text">
    <button ngbDropdownToggle id="actions" [disabled]="isDisabled()" 
    [ngClass]="myClass || 'btn  btn-outline-primary  shadow-none'">
     <span  class="me-2">{{ text}}</span>
   
   </button>
  </span>
  <span *ngIf='!text' >
    <button ngbDropdownToggle id="actions" 
    [ngClass]="myClass || 'btn  btn-outline-primary  shadow-none' ">
     <fa-icon icon="ellipsis-h"></fa-icon>
   </button>
  </span> 
 
  
  <div ngbDropdownMenu aria-labelledby="actions" class="position-absolute">
      <button *ngFor="let action of actions" 
      [id]="makeId(action)"
      class="dropdown-item" (click)="action.onclick ()" [hidden]='hide (action.hide)' [disabled]="disable(action.disable)">{{ action.name }}</button>
  </div>
</span>