

<div 
class=""
style="cursor:pointer; "
(click)="$event.preventDefault()"


  >
 

  <span class="me-2">
    {{ params?.name || params?.command }}
  </span>
  
  
  <span class="me-1">
    <button  class="btn btn-link text-danger p-0" type="button" (click)="favorite ()" >
      <fa-icon *ngIf="isFavorite ()" icon="heart"></fa-icon>
      <fa-icon *ngIf="!isFavorite ()" [ngClass]="{ invisible: !isFavorite () && (!isSelected && !hovering) }"  [icon]="['far', 'heart']"></fa-icon>
    </button>
  </span>

  <span class="me-1">
    <button *ngIf="(isSelected || hovering) &&  showCustom()" class="btn btn-link text-dark p-0" type="button" (click)="custom()">
      <fa-icon [icon]="['far', 'pen-field']"></fa-icon>
    </button>
  </span>

  <span class="float-end me-1">
    <button *ngIf="(isSelected || hovering) && showUser()" class="btn btn-link text-dark p-0" type="button" (click)="remove ()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </span>
</div>

 

