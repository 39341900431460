<div *ngIf="user" ngbDropdown class="d-inline-block" title="" placement="bottom-right">
    <button  class='header-button btn btn-link text-light text-decoration-none' id='usermenu' ngbDropdownToggle>
        <fa-icon icon="circle-user" size="2x" class="d-block"></fa-icon>
        {{ user.sub }}
    </button>
    <div ngbDropdownMenu aria-labelledby="usermenu"  class="myright">

        <button *ngIf="perms.user" id="usermenu-user-settings-prefs" 
        class='btn btn-link dropdown-item px-2' target="_self" (click)="goToProfile('preferences')" >
        <span class="me-1">
            <fa-icon [icon]="['far', 'address-card']"></fa-icon>
         </span>
        Preferences</button> 

        
        <button id="about-fastx"
         class='btn btn-link dropdown-item px-2' target="_self" (click)="showAbout()">
         <span class="me-1">
            <fa-icon [icon]="['far', 'circle-info']"></fa-icon>
         </span>
         
         About FastX</button> 
    
        <!-- <button *ngIf="0 && perms.utils.terminal" 
            id="usermenu-user-terminal" 
            class='btn btn-link dropdown-item' target="_self" 
            (click)="goToTerminal()" >Terminal</button>  -->
    
     
       
        <!-- <button *ngIf="perms.user" id="usermenu-user-settings-ssh" 
            class='btn btn-link dropdown-item' target="_self" (click)="goToProfile('ssh-keys')" >SSH Keys</button>  -->
        <button   id="usermenu-user-logout" 
            class='btn btn-link dropdown-item px-2' target="_self" (click)="logout ()" >
            <span class="me-1">
                <fa-icon [icon]="['far', 'power-off']"></fa-icon>
             </span>
            Sign Out</button>     
    </div>
</div> 