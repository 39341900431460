

<div 
class="d-flex justify-content-start flex-column flex-shrink mb-2 me-2 
border img-list img-list-h-container text-center position-relative bg-light"
style="cursor:pointer; "
[ngClass]="setClass ()"
(mouseenter)="toggleHover(true)" (mouseleave)="toggleHover(false)"
(click)="toggleSelect()"
(dblclick)="selected = true; start()"
[openDelay]="500"
[ngbPopover]="tip2"
    triggers="mouseenter:mouseleave"
    [openDelay]="1000"
   
    container="body"
    #pop2="ngbPopover"
  >


  <div class="img-list-h position-relative">
      <div  class="position-absolute d-flex flex-row justify-content-between  w-100 p-1">
        <span>
          <button  class="btn btn-link text-danger p-0 me-1" type="button" (click)="favorite ()" >
            <fa-icon *ngIf="isFavorite ()" icon="heart"></fa-icon>
            <fa-icon *ngIf="!isFavorite () && isSelected()"  [icon]="['far', 'heart']"></fa-icon>
          </button>
         
        </span>
       
        <span>


          <button [ngClass]='{ invisible: !hasSessions() }' class="btn btn-link text-dark p-0" type="button" (click)="custom()">
            <span class="badge badge-primary">{{ hasSessions() }}</span>
          </button>


          <button *ngIf="isSelected() && showUser()" class="btn btn-link text-dark p-0" type="button" (click)="remove ()">
            <fa-icon icon="times"></fa-icon>
          </button>
        </span>
        
      </div>


       <button *ngIf="isSelected()" 
       class="position-absolute  border-0 top-50 start-50 translate-middle  play" (click)="start()"
       style="z-index: 90"
       >
        <fa-icon size="6x" class="" icon="circle-play"></fa-icon>
      </button>
    

      <img *ngIf="hasIcon()" class="wh-100  img-list-h p-1" [src]="icon()" />
      <fa-icon class='position-absolute top-50 start-50 translate-middle text-primary' *ngIf="!hasIcon()" size="4x" [icon]="['fas', $any(faIcon())]"></fa-icon>
  </div>
  <div class="d-flex flex-row  flex-fill align-items-center justify-content-center">
      <span class="text-truncate">{{ name () }}</span>
  </div>
  <div class="d-flex flex-row justify-content-between   w-100 px-1"  >
    <span>&nbsp;</span>
    <button *ngIf="isSelected() && showCustom()" class="btn btn-link text-dark p-0" type="button" (click)="custom()">
      <fa-icon [icon]="['far', 'pen-field']"></fa-icon>
    </button>

     
    </div>
  </div>
  <ng-template #tip2>
    <div (click)="pop2.close ()" class="pop">
  <pre>
  Command: {{ data.command }}
  Name: {{ data.name }}
  Profile: {{ data.profile }}
  Tags: {{ (data.tags || [] ).join (', ') }}
  </pre>
    </div>
   
  </ng-template>
  


 

