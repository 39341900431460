<div class="modal-body">
    <div class="d-flex justify-content-center">
        <img class="w-50" src="{{ config.base}}/img/fastx" />
    </div>
    <div>FastX Build: <strong>{{ config.build }} {{ config.advanced ? "Advanced" : "" }}</strong></div>
    <div >Modules:</div>
    <ul>
        <li *ngFor="let i of modules">{{ i.name }}: {{ i.version }}</li>
    </ul>
    <!-- <div>This Server Id: <strong>{{ config.serverId }}</strong></div> -->
    <!-- <div>Logged In Server Id: <strong>{{ config.user.iss }}</strong></div> -->

    <div *ngIf="extension()">
        <a href="{{ config.base }}/extensions/fastx-1.1-fx.xpi" >
        Download FastX Addon for Firefox</a>
    </div>

    <div>
        <a href="https://www.starnet.com/help/">
        Documentation</a>
    </div>
    
    <div>
        <a href="http://www.starnet.com" target="_self">
            StarNet Communications Corp
        </a>
    </div>
    
    
</div>
