
         <div class="">
            <div class="d-flex justify-content-between">
               <h4 class="px-4" style="z-index: index 10000;">
                  <span class="">Applications </span>
                  <span class="me-3">
                  <fx-session-tag (changed)="onTagChanged ($event)" [tagsHaveBookmarks]="tagLengths ()" [selected]="tag"></fx-session-tag>
               </span>
                  <!-- <button *ngIf="showCustom()" class="h4 px-0 py-0 btn-link border-0 bg-transparent" (click)="custom({})">
                     <fa-icon [icon]="['far', 'circle-plus']"></fa-icon>
                 </button> -->
               </h4>
               
              

               
              
            </div>
              
          
  
           
            <!-- <div class="input-group input-group input-group input-group-sm position-relative">
                  <input 
                  (focus)="hasFocus = true"
                  (blur)="hasFocus = false"
                  class="form-control  position-relative shadow-none border-right-0" type="text" placeholder="Search Bookmarks" [(ngModel)]="textFilter" />
                  <button 
                  (click)="textFilter = ''" 
                  type="button" 
                  class="btn  bg-transparent border-left-0 input-group-text shadow-none outline-focus "  
                  [ngStyle]="{ 'border-color': hasFocus ? '#80bdff' : '' }">
                     <fa-icon [ngClass]="{ invisible: !textFilter.length, visible: textFilter.length }" 
                     
                     icon="xmark"></fa-icon>
                  </button>        
                  <span class="input-group-text py-0 ">
                    
                   
                  </span>
            </div> -->
           
         </div>
  

<div class="row">
   <div class="col">
      <!--  -->
      <div class="bookmark-container" *ngIf="getView() !== 'list'">
         <div *ngFor="let b of filteredBookmarks()"
               class="float-start d-flex justify-content-center me-1 mb-1"
         >
            <fx-session-bookmark-element 
            [view]="getView()"
            [data]="b" 
            [selected]="getSelected (b)"
            (click)="setSelected (b)"
            (started)="start ($event)"
            (createCustom)="custom($event)"
            ></fx-session-bookmark-element>
         </div>
      </div>
      <div class="bookmark-container" [ngClass]="{ 'd-none': getView() !== 'list' }">
         <app-grid [gridOptions]="gridOptions"></app-grid>
      </div>
   </div>
</div>
